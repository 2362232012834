import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { clearCookies } from '@iblai/ibl-web-react-common';
const Logout = () => {
  const location = useLocation();
  const handleLogout = (redirectTo) => {
    const logoutCalls = [
      fetch(`${process.env.REACT_APP_IBL_LMS_URL}/logout/`, {
        method: 'GET',
        credentials: 'include',
      }),
      fetch(`${process.env.REACT_APP_IBL_STUDIO_URL}/logout/`, {
        method: 'GET',
        credentials: 'include',
      }),
    ];
    if (process.env.REACT_APP_EXTERNAL_IDP_LOGOUT_URL) {
      logoutCalls.push(
        fetch(`${process.env.REACT_APP_EXTERNAL_IDP_LOGOUT_URL}`, {
          method: 'GET',
          credentials: 'include',
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.logout_url) {
              fetch(data.logout_url, {
                method: 'GET',
                credentials: 'include',
              });
            }
          })
      );
    }
    Promise.allSettled(logoutCalls).then(() => {
      localStorage.clear();
      clearCookies();
      window.location.href = redirectTo;
    });
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const redirectTo = queryParams.get('redirect-to');
    handleLogout(redirectTo);
  }, []);
};

export { Logout };
