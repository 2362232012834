import { useTranslation } from 'react-i18next';

export function LoginEmailUsernameInputSection({
  errorMessage,
  emailOrUsername,
  handleInputChange,
  placeholder = 'Email or Username',
}) {
  const { t } = useTranslation();

  return (
    <div className="w-layout-vflex auth-input-group visible">
      <input
        className="auth-input username w-input"
        autoFocus
        maxLength="256"
        name="emailOrUsername"
        placeholder={placeholder}
        type="text"
        value={emailOrUsername}
        onChange={handleInputChange}
      />
      {errorMessage.length !== 0 && (
        <div className="text-error" role="alert" aria-label="Required Field">
          {errorMessage}
        </div>
      )}
    </div>
  );
}
